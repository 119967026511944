// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-appointment-js": () => import("./../../../src/pages/appointment.js" /* webpackChunkName: "component---src-pages-appointment-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-info-index-js": () => import("./../../../src/pages/info/index.js" /* webpackChunkName: "component---src-pages-info-index-js" */),
  "component---src-pages-info-vpl-light-treatment-acne-js": () => import("./../../../src/pages/info/vpl-light-treatment/acne.js" /* webpackChunkName: "component---src-pages-info-vpl-light-treatment-acne-js" */),
  "component---src-pages-info-vpl-light-treatment-anti-aging-js": () => import("./../../../src/pages/info/vpl-light-treatment/anti-aging.js" /* webpackChunkName: "component---src-pages-info-vpl-light-treatment-anti-aging-js" */),
  "component---src-pages-info-vpl-light-treatment-couperose-rostra-js": () => import("./../../../src/pages/info/vpl-light-treatment/couperose-rostra.js" /* webpackChunkName: "component---src-pages-info-vpl-light-treatment-couperose-rostra-js" */),
  "component---src-pages-info-vpl-light-treatment-index-js": () => import("./../../../src/pages/info/vpl-light-treatment/index.js" /* webpackChunkName: "component---src-pages-info-vpl-light-treatment-index-js" */),
  "component---src-pages-info-vpl-light-treatment-pigmentations-removal-js": () => import("./../../../src/pages/info/vpl-light-treatment/pigmentations-removal.js" /* webpackChunkName: "component---src-pages-info-vpl-light-treatment-pigmentations-removal-js" */),
  "component---src-pages-info-vpl-light-treatment-vance-js": () => import("./../../../src/pages/info/vpl-light-treatment/vance.js" /* webpackChunkName: "component---src-pages-info-vpl-light-treatment-vance-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

